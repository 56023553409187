import { afterRender, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import { Preferences } from '@capacitor/preferences';
import { isPlatformBrowser } from '@angular/common';

const TOKEN_KEY = 'my-token';

@Injectable({
   providedIn: 'root'
})
export class AuthenticationService {

//   // Init with null to filter out the first value in a guard!
isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // null
token = '';

constructor(
	private http: HttpClient,
	@Inject(PLATFORM_ID) private platformId: Object
) {
	/*afterRender(()=>{
		this.loadToken(); 
	})*/

	if (isPlatformBrowser(this.platformId)) {
		this.loadToken(); 
	}
}

    async loadToken() {
 		const token = await Preferences.get({ key: TOKEN_KEY });
 		if (token && token.value) {
 			////console.log('set token: ', token.value);
			this.token = token.value;
 			this.isAuthenticated.next(true);
 		} else {
 			this.isAuthenticated.next(false);
 		}
 	}

    login(tokenr:any): Promise<void> {
        const token = tokenr;
        this.isAuthenticated.next(true);
        return Preferences.set({key: TOKEN_KEY, value: token});
 	}

    logout(): Promise<void> {
 		this.isAuthenticated.next(false);
 		return Preferences.remove({ key: TOKEN_KEY });
 	}
}
