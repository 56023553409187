<!-- 
<div class="desk-header-app">
  <app-desk-header style="width: 100%;"></app-desk-header>
</div> -->
<div class="flex ">

  <!-- Menú Lateral (Izquierda) -->
  <div [class.hidden]="!isMenuOpen" class="fixed inset-0 bg-black bg-opacity-50 z-10 " (click)="toggleMenu()">
  </div>

  <div [ngClass]="{'w-64': isMenuOpen, 'w-16': !isMenuOpen}"
    class="fixed top-0 left-0 h-full bg-indigo-600 text-white transition-all duration-300 ease-in-out z-20">
    <div class="flex items-center justify-between p-4">
      <span *ngIf="isMenuOpen" class="text-xl font-bold">Menú</span>
      <button (click)="toggleMenu()" class="text-2xl ">
        <i class="fas" [ngClass]="isMenuOpen ? 'fa-times' : 'fa-bars'"></i>
      </button>
    </div>
    <div class="mt-8">
      <ul>
        <li *ngFor="let option of menuOptions" class="py-3 px-6 hover:bg-indigo-700 cursor-pointer">
          <a [routerLink]="option.link" class="flex items-center space-x-4">
            <i [class]="option.icon" class="text-lg"></i>
            <span *ngIf="isMenuOpen" class="text-lg">{{ option.label }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>



</div>



<router-outlet></router-outlet>
<!-- 
<div class="desk-footer-app" *ngIf="isDesk">
  <app-desk-footer></app-desk-footer>
</div> -->

<!--<ion-router-outlet id="first" id="main-content"></ion-router-outlet>-->

<!-- 
<div class="fixed bottom-4 left-4 bg-white rounded-lg shadow-lg p-6 w-80 z-50" *ngIf="showPopup">

  <button class="absolute top-2 right-2 text-gray-500 hover:text-black focus:outline-none" (click)="closePopup()">
    ✕
  </button>


  <h2 class="text-lg font-bold text-gray-800">¡Oferta Relampago!</h2>
  <p class="mt-2 text-gray-600">Aprovecha el descuento exclusivo en este producto.</p>

 
  <div class="mt-4 flex justify-center">
    <img src="assets/img/oferta-producto.jpg" alt="Producto en oferta" class="w-24 h-24 object-contain" />
  </div>


  <div class="mt-4 text-center">
    <p class="text-lg font-semibold text-gray-800">Solo por: <span class="text-red-500">$99.99</span></p>
    <button class="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none">
      Ir a Producto
    </button>
    <button style="margin-left: 10px;"
      class="mt-4 px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none">
      Comprar
    </button>
  </div>
</div> -->