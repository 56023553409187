import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HandleInpSearchService {

  private txtBuscador: BehaviorSubject<string> = new BehaviorSubject<string>(this.getStoredSearchQuery());
  public txtBuscador$: Observable<string> = this.txtBuscador.asObservable();

  constructor() { }

  setTxtBuscador(product: string) {
    if (this.isBrowser()) {
      sessionStorage.setItem('searchQuery', product); // Store the query in sessionStorage
    }
    this.txtBuscador.next(product); // Update the observable
  }

  private getStoredSearchQuery(): string {
    if (this.isBrowser()) {
      return sessionStorage.getItem('searchQuery') || '';
    }
    return '';
  }

  private isBrowser(): boolean {
    return typeof window !== 'undefined' && typeof sessionStorage !== 'undefined';
  }
  
}
