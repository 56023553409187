import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuscadorService {
    /* Mantiene la variable de lo que se busca */
    private handleBusca: BehaviorSubject<any> = new BehaviorSubject<any>('');
    public handleBuscador$: Observable<any> = this.handleBusca.asObservable();

    /* cambia tue cuando hace el load */
    private handleLoad: BehaviorSubject<any> = new BehaviorSubject<any>(false);
    public handleLoad$: Observable<any> = this.handleLoad.asObservable();
  
    constructor() {}
    
    setBuscador(product : any) {
        this.handleBusca.next(product);
    }

    setLoad(bool : any) {
        this.handleLoad.next(bool);
    }

    clearInputValue() {
        this.handleBusca.next('');
    }
}